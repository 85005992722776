.product-card {
    height: 350px;
    background-color: #bbb;
    margin: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-position: center;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
}
.product-card-container {
    text-align: center;
    color: #7a0f0f;
    cursor: pointer;
}

.quick-view {
    position: absolute;
    max-height: 0px;
    overflow: hidden;
    z-index: 1;
    bottom: 0px;
    width: 100%;
    color: white;
    background-color: #e05151;
}

.product-card-container:hover {
    color: #a71010;
    z-index: 11;
    transform: scaleY(1.05);
    transition: transform 0.3s ease-out;
}
.product-card-container:hover .quick-view {
    max-height: 500px;
    transition: max-height 1s ease-in-out;
}
.product-card-container:hover .product-card {
    transition: all 0.25s ease-in;
}
