.image-preview-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-list {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  flex-direction: column;
  max-height: 400px;
  overflow: auto;
}


.image-list img {
  width: 100px;
  border: 1px solid #f5f5f5;
  padding: 2px;
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}

.image-preview img{
    border: 1px solid #f5f5f5;
    padding: 2px;
    height: 400px;
    max-width: 400px;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);    
}

.is-divider {
    height: 3px;
    display: block;
    background-color: rgba(0,0,0,.1);
    margin: 1em 0 1em;
    width: 100%;
    max-width: 30px;
}