/* font-family: 'Poppins', sans-serif; */
/* borde: 1rem solid rgba(0 ,0, 0,.1) */
/* outline:1rem solid rgba(0,0,0,.1)
border:2rem solid rgba(0,0,0,0.1)
outline-hover:2rem solid var(--black) */

.main-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 9%;
  background-color: #fff;
  box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.29);
}
.main-header .logo {
  /* font-size: 1rem;
  font-weight: bolder;
  color: #130f13;
  font-family: "Poppins", sans-serif; */
  width: 120px;
}
.header .link {
  font-size: 1.5rem;
  margin: 0 1rem;
  color: #000;
  font-family: "Poppins", sans-serif;
}
.header .link:hover {
  color: red;
}

.icons .icon {
  height: 2.2rem;
  width: 2.2rem;
  line-height: 4.5rem;
  border-radius: 0.5rem;
  background: #eee;
  color: #130f13;
  font-size: 2rem;
  margin-left: 0.3rem;
  text-align: center;
  cursor: pointer;
  padding: 5px;
}
.icon:hover {
  background: #130f13;
  color: #fff;
}
#menu {
  display: none;
}
.search-form {
  position: absolute;
  top: 110%;
  right: 1rem;
  width: 27rem;
  height: 3rem;
  background: #fff;
  border-radius: 0.5rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  border: none;
  box-shadow: 0 0.8rem 1rem rgba(0, 0, 0, 0.279);
}
.search-form Input {
  height: 100%;
  width: 100%;
  background: none;
  text-transform: none;
  font-size: 1.1rem;
  color: #130f13;
  padding: 0 1.5rem;
  font-family: "Poppins", sans-serif;
}

.search-form label {
  font-size: 2.2rem;
  padding-right: 1.5rem;
  color: #130f13;
  cursor: pointer;
}
.search-form label:hover {
  color: red;
}
.login-form {
  position: absolute;
  top: 110%;
  right: 2rem;
  width: 25rem;
  box-shadow: 0 0.8rem 1rem rgba(0, 0, 0, 0.279);
  padding: 2rem;
  border-radius: 0.5rem;
  background: #fff;
  text-align: center;
  font-family: "Poppins", sans-serif;
}
.login-form h3 {
  font-size: 2.5rem;
  text-transform: uppercase;
  color: #130f13;
}
.login-form .login-box {
  width: 100%;
  margin: 1rem 0;
  background: #eee;
  border-radius: 0.5rem;
  padding: 1rem;
  font-size: 1.1rem;
  color: #130f13;
  text-transform: none;
}
.login-form p {
  font-size: 1rem;
  padding: 0.5rem 0;
  color: #666;
}
.btn-unique {
  padding: 0.5rem 2rem;
  font-family: "Poppins", sans-serif;
  background: #fff;
  font-size: 1.1rem;
  border-radius: 3.5rem;
  border: 0.1rem solid #130f13;
  color: #130f13;
  cursor: pointer;
  text-transform: none;
}
.btn-unique:hover {
  background-color: #130f13;
  color: #fff;
}

@media only screen and (max-width: 991px) {
  .main-header {
    padding: 1rem;
  }
}
@media only screen and (max-width: 768px) {
  .only-desktop {
    display: none;
  }
  #menu {
    display: inline-block;
  }
  .main-header .header {
    position: absolute;
    top: 110%;
    right: 1.2rem;
    width: 18rem;
    box-shadow: 0 0.8rem 1rem rgba(0, 0, 0, 0.279);
    background: #fff;
    border-radius: 0.5rem;
  }
  .header .link {
    font-size: 1.6rem;
    margin: 1.2rem 2.2rem;
    display: block;
  }
  .login-form {
    right: 1rem;
    width: 20rem;
  }
  .login-form h3 {
    font-size: 2rem;
  }
  .login-form .login-box {
    padding: 1rem;
    font-size: 1rem;
  }
  .login-form p {
    font-size: 0.8rem;
    padding: 0.5rem 0;
    color: #666;
  }
  .btn-unique {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border-radius: 0.5rem;
  }
  .search-form {
    right: 1rem;
    width: 20rem;
  }
}
